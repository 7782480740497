// $(document).ready(function() {
  // $("#cookie_consent_accept").click(function() {
  //   setCookie("cookie_consent", 1, 365);
  // });

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
// });

// export default class cookieConsent {

//   constructor (cname, cvalue, exdays){
//     this.cname = cname;
//     this.cvalue = cvalue;
//     this.exdays = exdays;
//   }

//   function setCookie(cname, cvalue, exdays) {
//     var d = new Date();
//     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//     var expires = "expires="+ d.toUTCString();
//     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
//   }

//   const setCookie = (cname, cvalue, exdays) => {
//     let d = new Date();
//     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

//     let expires = "expires="+ d.toUTCString();
//     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
//   }; 
// }