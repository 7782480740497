// if ('serviceWorker' in navigator) {
// 	navigator.serviceWorker
// 		.register("./assets/js/service-worker.js")
// 		.then(function() {
// 			console.log('Service Worker Registered');
// 		});
// }

/* Start: Disable X3DOM's key event */
// document.onkeydown = function (e) {
//     if (e.which == 8 
//         || e.which == 32 
//         || e.which == 68 
//         || e.which == 70 
//         || e.which == 76 
//         || e.which == 77
//         || e.which == 87) {
//         // console.log('Disable');
//         return false;
//     }
// }
/* End: Disable X3DOM's key event */

$(document).ready(function() {

  /* Disable image draging */
  let imgList = document.getElementsByTagName("img");
  for (let i = 0; i < imgList.length; i++) {
    imgList[i].ondragstart = function() {
      return false;
    };
  }

  /* Easing menu */
  /*
  $(function() {
    $('.nav-item a').bind('click',function(event){
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top
      }, 500, 'easeOutQuad');
      event.preventDefault();
    });
  });
  */

  /* Toggle menu */
  /*
  $('#toggle_menu').click(function() {
    if ($('#toggle_nav').hasClass('out')) {
      $('#toggle_nav').animate({right: '-300px'}, 300).removeClass('out');
      return false;
    } else {
      $('#toggle_nav').animate({right: '0px'}, 300).addClass('out');
      return false;
    }
  });
  */

  // import {cookieConsent} from './cookie-consent.js'
  // const cookieConsent = require('/cookie-consent.js').default;

  $("#cookie_consent_accept").click(function() {
    setCookie("cookie_consent", 1, 365);
  });

});