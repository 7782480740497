$(document).ready(function(){
	var testimonials = {
		"testimonial_image_path": "assets/images/testimonials/",
		"testimonials": [
			{
				"client_brand": "APA Biotechs Care (June 18, 2019)",
				"client_name": "Punyawee Ratanapatarapong",
				"client_image": "punyawee-ratanapatarapong.png",
				"testimonial": "ความประทับใจแรกจากคุณเต้ย คือความตั้งใจจริงที่อยากจะช่วยและแก้ไขปัญหาที่เราเจอมาก่อนหน้านี้ รู้สึกดีตั้งแต่แรกเลยค่ะ พอได้ร่วมงาน คุณเต้ยอัพเดทงานค่อนข้างดี และพร้อมที่จะปรับให้งานตรงตามความต้องการเรามากที่สุด คุยง่าย ตรงเวลา ใครมองหาคนทำเวปนี้จะแนะนำให้ทุกคนที่มีโอกาสเลยค่ะ >///<"
			},
			{
				"client_brand": "SleepSocietyz (December 11, 2018)",
				"client_name": "Win Indarangkura Na Ayudhaya",
				"client_image": "win-indarangkura-na-ayudhaya.jpg",
				"testimonial": "As a business, SleepSocietyz needs our own Website not only to support our online customers but also to managing our real-time inventory. Toey quickly understood our business issues, gave good recommendations and helped us build a user-friendly solution for both our customers and our back-office. His work delivery was top-notch and allow our business to grow further with no interruption. It was a pleasure working with him."
			},
			{
				"client_brand": "Ganok Thaimassage München",
				"client_name": "Pimolpan Schmiedt",
				"client_image": "pimolpan-schmiedt.jpg",
				"testimonial": "รู้จักน้องเต้ยจากการเสิร์ชหานักเรียนไทยในเยอรมนี หาคนเขียนเว็บไซต์ คุณสามีอยากสนับสนุนน้องนักเรียนไทยมากกว่าที่จะไปจ้างมืออาชีพคนเยอรมนีด้วยกัน เจอกัน พูดคุยกันถูกคอ น้องเต้ยน่ารักค่ะ จ้างน้องเต้ยทำเว็บไซต์ 3 เว็บแล้วค่ะ น้องเต้ยเก่งนะคะ น้องทำงานรวดเร็ว ตรงจุดประสงค์  ราคางานสมกับคุณภาพค่ะ"
			},
			{
				"client_brand": "Warutti Jewelry Bangkok",
				"client_name": "Bat Ratchapolsitte",
				"client_image": "bat-ratchapolsitte.jpg",
				"testimonial": "รู้ว่าเต้ยทำเว็บเขียนเว็บแต่ได้มาใช้บริการตอนเพื่อนที่เป็นเจ้าของร้านจิวเวลรี่ต้องการอัพเดตเว็บไซต์ใหม่ให้ดูสวย สะอาดตาและง่ายต่อการใช้งานเลยติดต่อเต้ย เต้ยทำงานมืออาชีพและไปรับบรีฟด้วยตัวเอง เวลามีปัญหาอะไรก็เขียนถามเต้ยและเต้ยก็แก้ไขให้ทันที พร้อมให้คำแนะนำที่ดีค่ะ"
			},
			{
				"client_brand": "Happy Mommy Diary",
				"client_name": "Numwan Yathip Piriyapongsak",
				"client_image": "numwan-yathip-piriyapongsak.jpg",
				"testimonial": "เต้ยคือคนที่ทำให้หวานมีเว็ปไซต์เป็นของตัวเอง คุยกันรู้เรื่อง เข้าใจความต้องการของเรา เก็ทอะไรเร็ว  บอกปุ๊บได้ปั๊บ และเป็นไปตามที่เราคิด บางทีดีกว่าที่เราคิดได้ซะอีก เพราะเขาคือผู้เชี่ยวชาญ สรุปคือถ้าคุณไม่มีความรู้แต่อยากมีเว็ปไซต์ ปรึกษาเต้ยได้ ไม่ผิดหวังแน่นอนค่ะ"
			},
			{
				"client_brand": "The Space Condo",
				"client_name": "Pop Rapee Piriyapongsak",
				"client_image": "pop-rapee-piriyapongsak.jpg",
				"testimonial": "เต้ยมีความเป็นมืออาชีพมาก มีความรับผิดชอบ ดูแลเราดีมาก งานเร็วและดี ถูกใจมาก และยังคอยติดตามสอบถาม แก้ปัญหาให้ตลอดจนถึงทุกวันนี้ ตั้งแต่รู้จักน้องมาประทับใจในทุกๆ เรื่องครับ Recommended!"
			},
			{
				"client_brand": "Veerapol Battery",
				"client_name": "Nantaporn Kamolwat",
				"client_image": "nantaporn-kamolwat.jpg",
				"testimonial": "มีความเป็นมืออาชีพในการทำงานมาก เราต้องการรูปแบบไหนก็สามารถนำเสนอให้เราได้ ส่งงานรวดเร็ว"
			}
		]
	};

	var template = "{{#testimonials}}"
	+ "<div class='testimonial-item'>"
		+ "<div class='testimonial-content'>"
		+ "{{testimonial}}"
		+ "</div>"
		+ "<figure class='testimonial-figure'>"
		+ "<img class='testimonial-figure-image' alt='{{client_name}}' src='{{testimonial_image_path}}{{client_image}}'>"
		+ "<figcaption class='testimonial-figure-caption'>"
		+ "<div class='client-name'>{{client_name}}</div>"
		+ "<div class='client-brand'>{{client_brand}}</div>"
		+ "</figcaption>"
		+ "</figure>"
	+ "</div>"
	+ "{{/testimonials}}";

	var html = Mustache.to_html(template, testimonials);
	document.getElementById("testimonial_list_template").innerHTML = html;
});