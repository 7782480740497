$(document).ready(function(){
	var clients = {
		"client_image_path": "assets/images/clients/",
		"clients": [
			{
				"client_name": "APA Biotechs Care",
				"client_url": "#",
				"client_image_logo": "apa-biotechs-care-logo.jpg"
			},
			{
				"client_name": "SleepSocietyz",
				"client_url": "#",
				"client_image_logo": "sleepsocietyz-logo.jpg"
			},
			{
				"client_name": "SCG Packaging",
				// "client_url": "http://www.scgpackaging.com/",
				"client_url": "#",
				"client_image_logo": "scg-packaging-logo.png"
			},
			{
				"client_name": "Suranaree University of Technology",
				// "client_url": "http://www.sut.ac.th/",
				"client_url": "#",
				"client_image_logo": "sut-logo.png"
			},
			{
				"client_name": "Porntip Thailand",
				// "client_url": "https://www.porntipthailand.com/",
				"client_url": "#",
				"client_image_logo": "porntip-logo.png"
			},
			{
				"client_name": "The Space Condo",
				// "client_url": "http://thespacecondo.com/",
				"client_url": "#",
				"client_image_logo": "thespacecondo-logo.png"
			},
			{
				"client_name": "DSA Global",
				// "client_url": "http://dsa-global.com/",
				"client_url": "#",
				"client_image_logo": "dsa-global-logo.png"
			},
			{
				"client_name": "Ganok Thaimassage",
				// "client_url": "http://ganok-thaimassage.de/",
				"client_url": "#",
				"client_image_logo": "ganok-thaimassage-logo.png"
			},
			{
				"client_name": "Meechoke Rungroengkit Co., Ltd.",
				// "client_url": "http://www.mcktrailer.com/",
				"client_url": "#",
				"client_image_logo": "mck-logo.jpg"
			},
			{
				"client_name": "Som O House Hotel",
				// "client_url": "https://www.somohousehotel.com/",
				"client_url": "#",
				"client_image_logo": "somohotel-logo.png"
			},
			{
				"client_name": "Zenith Residence Hotel",
				// "client_url": "https://zenithresidence.com/",
				"client_url": "#",
				"client_image_logo": "zenith-residence-hotel-logo.png"
			},
			{
				"client_name": "Veerapol Battery",
				// "client_url": "http://www.vpbattery.com/",
				"client_url": "#",
				"client_image_logo": "vpbattery-logo.png"
			},
			{
				"client_name": "Kwanruen Group",
				// "client_url": "https://kwanruengroup.com/",
				"client_url": "#",
				"client_image_logo": "kwanruen-group-logo.png"
			},
			{
				"client_name": "Thai Cuisine in Palatine",
				// "client_url": "https://thaicuisineinpalatine.com/",
				"client_url": "#",
				"client_image_logo": "thai-cuisine-palatine-logo.png"
			},
			{
				"client_name": "UHL Gold",
				"client_url": "#",
				"client_image_logo": "uhl-logo.png"
			},
			{
				"client_name": "Rungnirun Vill 2009",
				// "client_url": "http://rv2009.com/",
				"client_url": "#",
				"client_image_logo": "rungnirun-vill-logo.jpg"
			},
			{
				"client_name": "Warutti Jewelry",
				// "client_url": "https://www.waruttijewelry.net/",
				"client_url": "#",
				"client_image_logo": "warutti-jewelry-logo.png"
			},
			{
				"client_name": "Happy Mommy Diary",
				// "client_url": "http://www.happymommydiary.net/",
				"client_url": "#",
				"client_image_logo": "happymommydiary-logo.jpg"
			},
			{
				"client_name": "Ch.Mitryont 1993 Co., Ltd.",
				// "client_url": "http://ch-mitryont.com/",
				"client_url": "#",
				"client_image_logo": "ch-mitryont-logo.png"
			}
		]
	};

	var template = "{{#clients}}<div class='client-item'><img alt='{{client_name}}' src='{{client_image_path}}{{client_image_logo}}'></div>{{/clients}}";

	var html = Mustache.to_html(template, clients);
	document.getElementById("client_list_template").innerHTML = html;
});