$(document).ready(function() {
  var footer_pagination_carousel = $('.footer-pagination-carousel');	

  footer_pagination_carousel.owlCarousel({
    autoHeight: true,
    center: true,
    items: 2,
    margin: 10,
    // nav: true,
    dots: false
  });

  $(".next").click(function(){
    footer_pagination_carousel.trigger('footer_pagination_carousel.next');
  });
  $(".prev").click(function(){
    footer_pagination_carousel.trigger('footer_pagination_carousel.prev');
  });
});