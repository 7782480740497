$(document).ready(function() {
  $('.client-list').owlCarousel({
    autoplay: true,
    autoHeight: true,
    center: false,
    items: 4,
    lazyLoad: true,
    loop: true,
    margin: 10,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false
      },
      768: {
        items: 3,
        nav: false
      },
      1024: {
        items: 3,
        nav: false
      },
      1280: {
        items: 3,
        nav: false
      },
      1440: {
        items: 4,
        nav: false
      }
    }
  });
});