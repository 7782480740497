window.onload = function () {
  particlesJS("particles", {
    "particles": {
      "number": {
        "value": 50,
        "density":
        {
          "enable": true,
          "value_area": 800
        }
      },
      "color": {
        "value": "#0D3D56"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 1,
          "color": "#0D3D56"
        },
        "polygon": {
          "nb_sides": 5
        }
      },
      "opacity": {
        "value": 0.8,
        "random": false,
        "anim": {
          "enable": true,
          "speed": 1,
          "opacity_min": 0.1,
          "sync": false
        }
      },
      "size": {
        "value": 20,
        "random": true,
        "anim": {
          "enable": true,
          "speed": 30,
          "size_min": 0.1,
          "sync": false
        }
      },
      "line_linked": {
        "enable": true,
        "distance": 150,
        "color": "#1496BB",
        "opacity": 1,
        "width": 2
      },
      "move": {
        "enable": true,
        "speed": 3,
        "direction": "none",
        "random": false,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable":false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    },
    "interactivity": {
      "detect_on": "canvas",
      "events": {
        "onhover": {
          "enable": true,
          "mode":" repulse"
        },
        "onclick":{
          "enable":true,
          "mode":"push"
        },
        "resize":true
      },
      "modes": {
        "grab": {
          "distance": 400,
          "line_linked":{
            "opacity": 1
          }
        },
        "bubble": {
          "distance": 400,
          "size": 30,
          "duration": 2,
          "opacity": 8,
          "speed": 3
        },
        "repulse": {
          "distance": 200,
          "duration": 0.4
        },
        "push": {
          "particles_nb": 4
        },
        "remove": {
          "particles_nb": 2
        }
      }
    },
    "retina_detect": true
  });
};